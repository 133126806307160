export const MODULE_NAME = 'shopify_cart';

export const STATE_INITIALIZED = 'initialized';
export const STATE_LOADING = 'loading';

export const STATE_CART = 'cart';
export const STATE_PRODUCTS = 'products';

export const STATE_SIZES = 'sizes';

export const STATE_SELECTED_ITEM = 'selected_item';
export const STATE_OVERLAY_SHOWN = 'overlay_shown';

export const GETTER_INITIALIZED = 'initialized';
export const GETTER_LOADING = 'loading';

export const GETTER_CART = 'cart';
export const GETTER_CART_WITH_PRODUCTS = 'GETTER_CART_WITH_PRODUCTS';
export const GETTER_USD_CART = 'usdCart';
export const GETTER_PRODUCTS = 'GETTER_PRODUCTS';

export const GETTER_SELECTED_ITEM = 'selectedItem';
export const GETTER_SIZES = 'selectedSizes';
export const GETTER_HAS_ITEMS = 'has_items';
export const GETTER_REQUIRES_SHIPPING = 'requires_shipping';
export const GETTER_OVERLAY_SHOWN = 'overlay_shown';

export const MUTATION_UPDATE_INITIALIZED = 'updateInitialized';
export const MUTATION_UPDATE_LOADING = 'updateLoading';
export const MUTATION_UPDATE_CART = 'updateCart';
export const MUTATION_UPDATE_SELECTED_ITEM = 'updateSelectedItem';
export const MUTATION_UPDATE_SIZES = 'updateSizes';
export const MUTATION_OVERLAY_SHOWN = 'updateOverlayShown';
export const MUTATION_UPDATE_PRODUCTS = 'MUTATION_UPDATE_PRODUCTS';

export const ACTION_INIT_CART = 'initCart';
export const ACTION_GET_CART = 'getCart';
export const ACTION_SET_CART = 'setCart';
export const ACTION_ADD_TO_CART = 'addToCart';
export const ACTION_REMOVE_ITEM = 'removeItem';
export const ACTION_REMOVE_ITEMS = 'removeItems';
export const ACTION_CHANGE_QUANTITY = 'changeQuantity';
export const ACTION_EDIT_ITEM = 'editItem';
export const ACTION_UPDATE_SIZES = 'updateSizes';
export const ACTION_UPDATE = 'update';
export const ACTION_UPDATE_ATTRIBUTES = 'updateAttributes';
export const ACTION_CHANGE = 'change';
export const ACTION_CLEAR = 'clear';
export const ACTION_ERROR = 'error';
export const ACTION_SET_MINICART_LOADING = 'setMinicartLoading';
export const ACTION_SUCCESS = 'success';

export const ITEM_PROPERTY_ADD_TO_CART_AT = '_add_to_cart_at';
export const ITEM_PROPERTY_BUNDLE_PRODUCT = 'bundle_product';
export const ITEM_PROPERTY_BUNDLE_KEY = '_bundle_key';
export const ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT = '_bundle_main_product';
export const ITEM_PROPERTY_WITH_MEMBER_PRODUCT = '_with_member_product';
export const ITEM_PROPERTY_SELLING_PLAN = '_selling_plan';

export const ITEM_PROPERTY_PURCHASE_OPTION = '_purchase_option';
