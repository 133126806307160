export const isProductHasOnlyDefaultVariant = (product) => {
    if (!product) {
        return false;
    }

    const variants = product?.variants;
    if (!variants) {
        return false;
    }

    if (!variants?.length) {
        return false;
    }

    if (variants?.length !== 1) {
        return false;
    }

    const variant = variants[0];
    if (!variant) {
        return false;
    }

    if (variant?.option2) {
        return false;
    }

    if (variant?.option3) {
        return false;
    }

    if (variant?.option1?.toLowerCase() !== 'default title') {
        return false;
    }

    const options_with_values = product?.options_with_values || product?.options;
    if (!options_with_values) {
        return false;
    }

    if (options_with_values?.length > 1) {
        return false;
    }

    const option = options_with_values[0];
    if (!option) {
        return false;
    }

    const name = option?.name;
    if (!name) {
        return false;
    }

    return name?.toLowerCase() === 'title';
};

export default {
    isProductHasOnlyDefaultVariant
};
