export const MODULE_NAME = 'boxes';

export const STATE_LOADING = 'loading';

export const STATE_BOX_CONFIGURATOR_OPENED = 'box_configurator_opened';
export const STATE_SELECTED_BOX = 'selected_box';

export const GETTER_BOX_ITEMS = 'box_items';
export const GETTER_BOX_UPSELL_ITEMS = 'box_upsell_items';
export const GETTER_BOX_SUBSCRIPTION_ITEMS = 'box_subscription_items';
export const GETTER_BOX_ITEM_KEYS = 'box_item_keys';
export const GETTER_SELECTED_BOX = 'selected_box';
export const GETTER_BOXES = 'boxes';
export const GETTER_HAS_BOXES = 'has_boxes';
export const GETTER_BOX_CONFIGURATOR_OPENED = 'box_configurator_opened';

export const GETTER_BOX_IMAGE = 'box_image';

export const MUTATION_UPDATE_BOX_CONFIGURATOR_OPENED = 'updateBoxConfiguratorOpened';
export const MUTATION_UPDATE_SELECTED_BOX = 'updateSelectedBox';

export const ACTION_UPDATE_BOX_CONFIGURATOR_OPENED = 'updateBoxConfiguratorOpened';
export const ACTION_REMOVE_BOX = 'removeBox';
export const ACTION_REMOVE_ALL_BOXES = 'removeAllBoxes';

export const ITEM_PROPERTY_BOX_ID = '_bundle_id';
export const ITEM_PROPERTY_BOX_GROUP = '_box_group';
export const ITEM_PROPERTY_BOX_HANDLE = '_box_handle';
export const ITEM_PROPERTY_BOX_TITLE = '_box_title';
export const ITEM_PROPERTY_BOX_IMAGE = '_box_image';
export const ITEM_PROPERTY_BOX_SELLING_PLAN = '_selling_plan';
export const ITEM_PROPERTY_DISCOUNT_VALUE = '_discount_value';
export const ITEM_PROPERTY_FIXED_DISCOUNT_VALUE = '_fixed_discount_value';
export const ITEM_PROPERTY_IS_LASH = '_is_lash';
export const ITEM_PROPERTY_NEEDS_PRICE_UPDATE = '_needs_price_update';
export const ITEM_PROPERTY_BOX_PURCHASE_OPTION = '_purchase_option';
export const ITEM_PROPERTY_FREQUENCY_INTERVAL = '_frequency_interval';
export const ITEM_PROPERTY_FREQUENCY_UNIT = '_frequency_unit';
export const ITEM_PROPERTY_PRICING_STRATEGY = '_pricing_strategy';
export const ITEM_PROPERTY_MEMBER_DISCOUNT_VALUE = '_member_discount_value';
export const ITEM_PROPERTY_MEMBER_DISCOUNT_APPLIED = '_member_discount_applied';
export const ITEM_PROPERTY_IS_RETAIL_PRODUCT = '_is_retail';

export const ITEM_PROPERTY_INVETERATE_SUBSCRIPTION = '_inveterate_subscription';

export const ITEM_PROPERTY_UPSELL = '_upsell';
export const ITEM_PROPERTY_ADDON = '_addon';
export const ITEM_PROPERTY_SUBSCRIPTION_UPSELL = '_subscription_upsell';

export const PURCHASE_OPTION_SUBSCRIPTION   = 'subscription';
export const PURCHASE_OPTION_ONETIME        = 'onetime';

export const CART_ATTRIBUTE_BOX_HANDLE = '_box_handle';
export const CART_ATTRIBUTE_BOX_TITLE = '_box_title';
export const CART_ATTRIBUTE_BOX_IMAGE = '_box_image';
export const CART_ATTRIBUTE_BOX_FREQUENCY = '_box_frequency';
export const CART_ATTRIBUTE_BOX_FREQUENCY_UNIT = '_box_frequency_unit';
export const CART_ATTRIBUTE_BOX_ONETIMES = '_box_onetimes';
export const CART_ATTRIBUTE_BOX_CURRENCY = '_box_currency';
export const CART_ATTRIBUTE_STATUS = 'status';
export const CART_ATTRIBUTE_PRICING_STRATEGY = '_box_pricing_strategy';
export const CART_ATTRIBUTE_DISCOUNT_VALUE = '_box_discount';
export const CART_ATTRIBUTE_DISCOUNT_TYPE = '_box_discount_type';

export const PRICING_STRATEGY_FIXED_DISCOUNT = "PRICING_STRATEGY_FIXED_DISCOUNT";
export const PRICING_STRATEGY_VOLUME_DISCOUNT = "PRICING_STRATEGY_VOLUME_DISCOUNT";

export const DISCOUNT_TYPE_PERCENTAGE = "percentage";

export const DEFAULT_STATE = {
    [STATE_LOADING]: true,
    [STATE_BOX_CONFIGURATOR_OPENED]: false,
    [STATE_SELECTED_BOX]: null
};
