export const MODULE_NAME = 'bundles';

export const STATE_SELECTED_BUNDLE = 'selected_bundle';
export const STATE_BUNDLE_CONFIGURATOR_OPENED  = 'bundle_configurator_opened';

export const GETTER_BUNDLE_ITEMS = 'bundle_items';
export const GETTER_BUNDLE_CHILD_ITEMS = 'bundle_child_items';
export const GETTER_BUNDLE_ITEM_KEYS = 'bundle_item_keys';
export const GETTER_BUNDLE_GROUPED_ITEMS = 'bundle_grouped_items';
export const GETTER_BUNDLES = 'bundles';
export const GETTER_HAS_BUNDLES = 'has_bundles';
export const GETTER_SELECTED_BUNDLE = 'selected_bundle';
export const GETTER_SHOW_BUNDLE_CONFIGURATOR = 'show_bundle_configurator';

export const ITEM_PROPERTY_BUNDLE_KEY = '_bundle_key';
export const ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT = '_bundle_main_product';

export const MUTATION_UPDATE_SELECTED_BUNDLE =
    'MUTATION_UPDATE_SELECTED_BUNDLE';

export const MUTATION_UPDATE_BUNDLE_CONFIGURATOR_OPENED =
    'MUTATION_UPDATE_BUNDLE_CONFIGURATOR_OPENED';

export const ACTION_UPDATE_BUNDLE_CONFIGURATOR_OPENED =
    'updateBundleConfiguratorOpened';

export const DEFAULT_STATE = {
    [STATE_SELECTED_BUNDLE]: null,
    [STATE_BUNDLE_CONFIGURATOR_OPENED]: false
};
