import Bugsnag, { PREFIX } from '@/helpers/bugsnag';
import ls from 'localstorage-slim';

export const windowVariableLoaded = async (variable) => {
    let interval = null;
    let timeout = null;

    if (!!window[variable]) {
        return window[variable];
    }

    return new Promise((resolve, reject) => {
        interval = setInterval(() => {
            if (!window[variable]) {
                return;
            }

            if (!!interval) {
                clearInterval(interval);
            }

            if (!!timeout) {
                clearTimeout(timeout);
            }

            document.dispatchEvent(new CustomEvent('windowVariableLoaded', {
                detail: {
                    variable,
                }
            }));

            resolve(window[variable]);
        }, 100);

        timeout = setTimeout(() => {
            if (!!interval) {
                clearInterval(interval);
            }

            if (!!timeout) {
                clearTimeout(timeout);
            }

            document.dispatchEvent(new CustomEvent('windowVariableLoadFailed', {
                detail: {
                    variable,
                }
            }));

            reject(new Error("Wishlist load timeout"));
        }, 15 * 60 * 1000);
    });
};

export const WishlistSDK = async () => {
    try {
        return await windowVariableLoaded("WishListHero_SDK");
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Wishlist error`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                });
            });
        } catch (error) {}
    }

    return null;
};

export const requestWishlistHash = async () => {
    try {
        const sdk = await WishlistSDK();
        if (!sdk) {
            return null;
        }

        const { GetWishListHash } = sdk;
        if (!GetWishListHash) {
            return null;
        }

        return GetWishListHash(true);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch wishlist hash`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const getWishlistHash = async () => {
    try {
        const storageKey = `${window?.__st?.cid || ""}:wishlist:hash`;

        let cachedWishlistHash = ls?.get(storageKey);
        if (!cachedWishlistHash) {
            cachedWishlistHash = await requestWishlistHash();

            try {
                ls?.set(storageKey, cachedWishlistHash, {
                    ttl: 3600,
                });
            } catch (error) {}
        }

        return cachedWishlistHash;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch wishlist hash`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const requestItems = async () => {
    try {
        const sdk = await WishlistSDK();
        if (!sdk) {
            return [];
        }

        const { GetWishListItemsFromServer } = sdk;
        if (!GetWishListItemsFromServer) {
            return [];
        }

        const response = await GetWishListItemsFromServer();
        if (!response) {
            return [];
        }

        if (!!Array?.isArray(response)) {
            return response;
        }

        const { ok } = response;
        if (!ok) {
            return [];
        }

        const { error } = response;
        if (!error) {
            return [];
        }

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to request wishlist items`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}
    }

    return [];
};

export const getItems = async () => {
    try {
        const storageKey = `${window?.__st?.cid || ""}:wishlist:items`;

        let cachedWishlistItems = ls?.get(storageKey);
        if (!cachedWishlistItems) {
            cachedWishlistItems = await requestItems();

            try {
                ls?.set(storageKey, cachedWishlistItems, {
                    ttl: 3600,
                });
            } catch (error) {}
        }

        return cachedWishlistItems;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to request wishlist items`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const addVariantItem = async ({
    product_id,
    url,
    variant_id,
    price,
    title,
    image,
    customer_id = undefined,
    hash = null
}) => {
    try {
        const sdk = await WishlistSDK();
        if (!sdk) {
            return null;
        }

        const { AddWishListItem } = sdk;
        if (!AddWishListItem) {
            return null;
        }

        const response = await AddWishListItem({
            CustomerId: customer_id,
            ProductId: product_id,
            ProductTitle: title,
            ProductPrice: price,
            ProductLink: url,
            ProductVariantId: variant_id,
            ProductImage: image,
            WishlistHash: hash
        });

        if (!response) {
            return null;
        }

        const storageKey = `${window?.__st?.cid || ''}:wishlist:items`;

        try {
            ls?.remove(storageKey);
        } catch(error) {}

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to add variant to wishlist`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                    product_id,
                    url,
                    variant_id,
                    price,
                    title,
                    image,
                    customer_id,
                    hash,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const removeVariantItem = async ({ variant_id, hash = null }) => {
    try {
        const sdk = await WishlistSDK();
        if (!sdk) {
            return null;
        }

        const { DeleteWishListItem } = sdk;
        if (!DeleteWishListItem) {
            return null;
        }

        const response = await DeleteWishListItem({
            ProductVariantId: variant_id,
            WishlistHash: hash
        });

        if (!response) {
            return null;
        }

        const storageKey = `${window?.__st?.cid || ""}:wishlist:items`;

        try {
            ls?.remove(storageKey);
        } catch (error) {}

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to remove variant to wishlist`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    response: error?.data || error?.response,
                    error,
                    variant_id,
                    hash,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export default {
    getWishlistHash,
    getItems,
    addVariantItem,
    removeVariantItem
};
